import React from 'react';
import { motion } from 'framer-motion';
import Database from './database.png';
import Soket from './Socket.png';
import Tilwind from './Tilwind.png';
import FileReact from './Reactttt.png';
import FileNode from './Node.png';
import Figma from './Figma.png';

function Expert() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  };

  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const features = [
    {
      title: 'Database',
      description:
        'I AM Database Administrator ensures data integrity, security, and performance, expertly managing database systems to support seamless, reliable operations',
      image: Database,
    },
    {
      title: 'Socket Programming',
      description:
        'I Am Socket programming allows devices to communicate over a network by exchanging data via TCP or UDP through endpoints called sockets.',
      image: Soket,
    },
    {
      title: 'Tailwind CSS',
      description:
        'I Am Good At Tailwind CSS and Normal CSS, a utility-first framework with predefined classes for rapid, responsive UI development.',
      image: Tilwind,
    },
    {
      title: 'React',
      description:
        'I Am A good React developer who creates efficient, scalable, and user-friendly interfaces, mastering component architecture and modern JavaScript.',
      image: FileReact,
    },
    {
      title: 'Node.js',
      description:
        'I am Good at Node.js, a runtime environment that allows JavaScript to be executed on the server side, enabling scalable, event-driven applications.',
      image: FileNode,
    },
    {
      title: 'Figma',
      description:
        'I am good at Figma, a collaborative design tool for creating UI, wireframes, and prototypes, with real-time teamwork and cloud access.',
      image: Figma,
    },
  ];

  return (
    <div>
      <section className="py-5">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-2xl mx-auto text-center"
            initial="initial"
            animate="animate"
            variants={fadeInUp}
          >
            <h2 className="text-4xl font-extrabold mb-6 text-gray-800">What I Do</h2>
            <p className="text-gray-600 mb-12">
              I specialize in the following areas, and I invite you to connect with me through the platforms listed below.
            </p>
          </motion.div>
          <motion.div
            className="flex flex-wrap -mx-4 mt-12"
            variants={staggerContainer}
            initial="initial"
            animate="animate"
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="w-full md:w-1/3 px-4 mb-8"
                variants={fadeInUp}
              >
                <div className="group relative rounded-md bg-white shadow-lg p-8 overflow-hidden transform hover:-translate-y-2 transition-transform duration-300 ease-in-out">
                  {/* Background animation */}
                  <div className="absolute inset-0 z-0 bg-teal-200 scale-x-0 group-hover:scale-x-100 origin-left transition-transform duration-500 ease-in-out"></div>
                  {/* Content */}
                  <div className="relative z-30">
                    <img
                      src={feature.image}
                      alt={feature.title}
                      className="w-10/12 h-48 mx-auto mb-4"
                    />
                    <h3 className="text-2xl font-semibold mb-4 text-gray-800">{feature.title}</h3>
                    <p className="text-gray-600 mb-4">{feature.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default Expert;
